<template>
  <div :class="containerClasses">
    <div v-if="isMobile" class="font-bold mb-12">Setelan profile</div>
    <div class="flex">
      <div class="mr-4">
        <mascot-avatar></mascot-avatar>
      </div>
      <div class="flex-1 flex flex-col">
        <div class="flex items-center">
          <h2 class="font-bold mr-2">{{data.Name}}</h2>
          <router-link to="/user/profile">
            <b-button type="is-light" icon-right="pencil" size="is-small" rounded style="width:28px"></b-button>
          </router-link>
        </div>
        <div class="flex">
          <b-icon icon="account" type="is-light" class="mr-2"></b-icon>
          <span>{{data.Code || '-'}}</span>
        </div>
        <div class="flex">
          <b-icon icon="phone" type="is-light" class="mr-2"></b-icon>
          <span>{{((data.PhoneCode || '') + data.PhoneNo) || '-'}}</span>
        </div>
        <div class="flex">
          <b-icon icon="email" type="is-light" class="mr-2"></b-icon>
          <span>{{data.UserName || '-'}}</span>
        </div>
        <div class="flex">
          <b-icon icon="home" type="is-light" class="mr-2"></b-icon>
          <span>{{data.Addresss || '-'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MascotAvatar: () => import('../MascotAvatar.vue')
  },
  props: {
    data: Object
  },
  computed: {
    containerClasses () {
      return [
        'has-background-primary text-white p-8 mb-8',
        {
          '-mt-8 md:-mt-12 -mx-4 rounded-b-2xl': this.isMobile
        },
        {
          'rounded-lg p-8': !this.isMobile
        }
      ]
    }
  }
}
</script>

<style>

</style>
